<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on">Edit</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Edit expense record
        <v-spacer></v-spacer>
        <v-btn small @click="deleteExpense()">Delete</v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          prepend-icon="mdi-receipt"
          v-model="invoiceNumber"
          label="Invoice #"
          required
          :error-messages="invoiceNumberErrors"
          @input="$v.invoiceNumber.$touch()"
          @blur="$v.invoiceNumber.$touch()"
        ></v-text-field>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              :error-messages="dateErrors"
              @input="$v.date.$touch()"
              @blur="$v.date.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field
          v-model="issuedBy"
          label="Issued by"
          prepend-icon="mdi-account"
          required
          :error-messages="issuedByErrors"
          @input="$v.issuedBy.$touch()"
          @blur="$v.issuedBy.$touch()"
        ></v-text-field>
        <v-textarea
          v-model="description"
          label="Description"
          prepend-icon="mdi-cart"
          auto-grow
        ></v-textarea>
        <v-text-field
          prepend-icon="mdi-folder-open"
          v-model="category"
          label="Category"
        ></v-text-field>
        <v-text-field
          prepend-icon="mdi-currency-usd"
          v-model="amount"
          label="Pre-tax amount"
          required
          :error-messages="amountErrors"
          @input="$v.amount.$touch()"
          @blur="$v.amount.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="tax"
          label="Tax paid"
          prepend-icon="mdi-currency-usd"
          required
          :error-messages="taxErrors"
          @input="$v.tax.$touch()"
          @blur="$v.tax.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="totalAmount"
          label="Total"
          prepend-icon="mdi-currency-usd"
          required
          :error-messages="totalAmountErrors"
          @input="$v.totalAmount.$touch()"
          @blur="$v.totalAmount.$touch()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          :disabled="this.$v.$anyError"
          color="primary"
          text
          @click="updateExpense"
          >Update</v-btn
        ><v-spacer></v-spacer>
        <v-btn text @click="cancelDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";
import { DateTime } from "luxon";

export default {
  mixins: [validationMixin],

  validations: {
    invoiceNumber: { required },
    date: { required },
    issuedBy: { required },
    amount: { decimal, required },
    tax: { decimal, required },
    totalAmount: { decimal, required },
  },
  props: ["expense"],
  data: () => ({
    menu: null,
    toggleDatePicker: false,
    invoiceNumber: null,
    issuedBy: null,
    date: null,
    amount: null,
    description: null,
    category: null,
    tax: null,
    totalAmount: null,
    menuStart: null,
    dialog: false,
  }),
  computed: {
    prefs() {
      return this.$store.getters.preferences;
    },
    invoiceNumberErrors() {
      const errors = [];
      if (!this.$v.invoiceNumber.$dirty) return errors;
      !this.$v.invoiceNumber.required && errors.push("Invoice number required");
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("Date required");
      return errors;
    },
    issuedByErrors() {
      const errors = [];
      if (!this.$v.issuedBy.$dirty) return errors;
      !this.$v.issuedBy.required &&
        errors.push("Required: Who did you pay this expense to?");
      return errors;
    },
    totalAmountErrors() {
      const errors = [];
      if (!this.$v.totalAmount.$dirty) return errors;
      !this.$v.totalAmount.required && errors.push("Required");
      !this.$v.totalAmount.decimal && errors.push("must be a number");
      return errors;
    },
    taxErrors() {
      const errors = [];
      if (!this.$v.tax.$dirty) return errors;
      !this.$v.tax.required && errors.push("Required");
      !this.$v.tax.decimal && errors.push("must be a number");
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push("Required");
      !this.$v.amount.decimal && errors.push("must be a number");
      return errors;
    },
  },

  methods: {
    simpleDate(d) {
      let dt = DateTime.fromISO(d);
      return dt.toISODate();
    },
    deleteExpense() {
      this.dialog = false;
      this.$store.dispatch("deleteExpense", this.expense._id);
    },
    toCents(string) {
      let number = parseFloat(string);
      let cents = Math.round(number * 100);
      return cents;
    },
    toDollars(string) {
      return parseInt(string) / 100;
    },
    updateExpense() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        let payload = {
          _id: this.expense._id,
          invoiceNumber: this.invoiceNumber,
          date: DateTime.fromISO(this.date),
          issuedBy: this.issuedBy,
          description: this.description,
          category: this.category,
          amount: this.toCents(this.amount),
          tax: this.toCents(this.tax),
          totalAmount: this.toCents(this.totalAmount),
        };
        this.$store.dispatch("updateExpense", payload);
        this.dialog = false;
        this.resetForm();
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" },
        });
      }
    },
    cancelDialog() {
      //   this.$v.$reset();
      this.resetForm();
      this.dialog = false;
    },
    resetForm() {
      this.invoiceNumber = this.expense.invoiceNumber;
      this.issuedBy = this.expense.issuedBy;
      this.date = this.simpleDate(this.expense.date);
      this.description = this.expense.description;
      this.category = this.expense.category;
      this.amount = this.toDollars(this.expense.amount);
      this.tax = this.toDollars(this.expense.tax);
      this.totalAmount = this.toDollars(this.expense.totalAmount);
      this.$v.$reset();
    },
  },
  created() {
    this.invoiceNumber = this.expense.invoiceNumber;
    this.issuedBy = this.expense.issuedBy;
    this.date = this.simpleDate(this.expense.date);
    this.description = this.expense.description;
    this.category = this.expense.category;
    this.amount = this.toDollars(this.expense.amount);
    this.tax = this.toDollars(this.expense.tax);
    this.totalAmount = this.toDollars(this.expense.totalAmount);
  },
};
</script>

<style scoped>
</style>